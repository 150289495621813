import { noAuthReq, authReq, authReqGet } from './generalRequest'
import cookieHandler from '../../../components/utils/cookies/cookieHandler'

function authConfig() {
	return {
		headers: { Authorization: `Bearer ${cookieHandler.getToken()}` },
	}
}
export default {
	async login(email, password) {
		var payload = {
			email: email,
			password: password,
		}
		return noAuthReq('/tokens', 'post', payload)
	},
	async logout() {
		return authReq('/tokens/me', 'delete', {}, authConfig())
	},
	updateUserInfo(payload) {
		return authReq('/users/me', 'patch', payload, authConfig())
	},
	getUserInfo() {
		return authReq('/users/me', 'get', {}, authConfig())
	},
	updateUserEmail(payload) {
		return authReq('/users/me/email', 'patch', payload, authConfig())
	},
	updateUserPassword(payload) {
		return authReq('/users/me/password', 'patch', payload, authConfig())
	},
	getCards() {
		return authReq('/users/me/payment_cards', 'get', {}, authConfig())
	},
	getCoffees() {
		return authReq('/users/me/coffees', 'get', {}, authConfig())
	},
	getCardAddress(payload) {
		return authReq(`/users/me/payment_cards/${payload}/billing_address`, 'get', {}, authConfig())
	},
	updateUserCard(payload) {
		return authReq('/users/me/payment_cards', 'post', payload, authConfig())
	},
	getAddresses() {
		return authReq('/users/me/addresses', 'get', {}, authConfig())
	},
	createAddress(payload) {
		return authReq('/users/me/addresses', 'post', payload, authConfig())
	},
	editAddress(payload) {
		return authReq(`/users/me/addresses/${payload.id}`, 'patch', payload, authConfig())
	},
	deleteAddress(payload) {
		return authReq(`/users/me/addresses/${payload.id}`, 'delete', payload, authConfig())
	},
	getRecurrables() {
		return authReq('/users/me/recurrables', 'get', {}, authConfig())
	},
	createRecurrable(payload) {
		return authReq('/users/me/recurrables', 'post', payload, authConfig())
	},
	pauseRecurrable(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/pause`, 'patch', payload, authConfig())
	},
	unpauseRecurrable(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/unpause`, 'patch', {}, authConfig())
	},
  cancelRecurrable(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/cancel`, 'patch', payload, authConfig())
	},
	getRecurrableCoffees(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/available_coffees`, 'get', {}, authConfig())
	},
	editRecurrable(payload) {
		return authReq(`/users/me/recurrables/${payload.id}`, 'patch', payload, authConfig())
	},
  editRecurrableBagSize(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/bag_size`, 'patch', payload, authConfig())
	},
	getOrdersHistory(payload) {
		return authReqGet('/users/me/orders/history', 'get', {page: payload.page}, authConfig())
	},
	getOrders() {
		return authReqGet('/users/me/orders/upcoming', 'get', {}, authConfig())
	},
	skipOrder(payload) {
		return authReq(`/users/me/orders/${payload.order_id}/skip`, 'patch', {}, authConfig())
	},
  asapOrder(payload) {
		return authReq(`/users/me/orders/${payload.order_id}/asap`, 'patch', {}, authConfig())
	},
	delayOrder(payload) {
		return authReq(`/users/me/orders/${payload.id}/delay`, 'patch', { days: payload.days }, authConfig())
	},
	checkSchedule(payload) {
    var id = payload.id
    delete payload.id
		return authReqGet(`/users/me/orders/${id}/scheduler`, 'get', payload , authConfig())
	},
	updateOrder(payload) {
		return authReq(`/users/me/orders/${payload.order_id}`, 'patch', payload, authConfig())
	},
	updateOrderItems(payload) {
		return authReq(`/users/me/orders/${payload.order_id}/order_items/${payload.item_id}`, 'patch', payload, authConfig())
	},
	createUserFunnel(payload) {
		return noAuthReq('/users/d2c/funnel', 'post', payload)
	},
	createUserStore(payload) {
		return noAuthReq('/users/d2c/store', 'post', payload)
	},
	redeemVoucher(payload) {
		return authReq(`vouchers/${payload.code}/redeem`, 'post', payload, authConfig())
	},
	validateVoucher(payload) {
		return authReq(`vouchers/${payload.code}/validate`, 'post', payload.body, authConfig())
	},
  claimIdentity( payload ) {
    return authReq('users/claim', 'post', payload,authConfig())
  },
	rateNewCoffee(payload) {
		return authReq(`/users/me/coffee-ratings`, 'post', payload, authConfig())
	},
  editCoffeeRating(payload) {
		return authReq(`/users/me/coffee-ratings/${payload.id}`, 'patch', payload, authConfig())
	},
  rateRecurrables(payload) {
    return authReqGet(`/users/me/coffee-ratings/${payload.id}/recurrables`, 'get', {}, authConfig())
  },
  whitelist(payload) {
    return authReq(`/users/me/coffees/whitelist`, 'post', payload, authConfig())
  },
  alwaysSend(payload) {
    return authReq(`/users/me/coffees/always-send`, 'post', payload, authConfig())
  },
	sendEmailInvites(payload) {
    return authReq(`/users/me/invite`, 'post', payload, authConfig())
  },
  referralStats() {
    return authReqGet(`/users/me/referral_stats/`, 'get', {}, authConfig())
  },
	orderAvailableProductAddons(payload) {
    return authReqGet(`/users/me/orders/${payload.order_id}/available_product_addons`, 'get', {}, authConfig())
  },
	addOrderAddons(payload) {
    return authReq(`/users/me/orders/${payload.order_id}/order_items`, 'post', payload, authConfig())
	},
	deleteOrderAddon(payload) {
		return authReq(`/users/me/orders/${payload.order_id}/order_items/batch_destroy`, 'delete', payload, authConfig())
	},
	availableProductAddons(payload) {
    return authReqGet(`/users/me/recurrables/${payload.recurrable_id}/available_product_addons`, 'get', {}, authConfig())
  },
	addAddons(payload) {
    return authReq(`/users/me/recurrables/${payload.recurrable_id}/recurrable_addons`, 'post', payload, authConfig())
	},
	updateAddons(payload) {
    return authReq(`/users/me/recurrables/${payload.recurrable_id}/recurrable_addons`, 'patch', payload, authConfig())
	},
	deleteAddon(payload) {
		return authReq(`/users/me/recurrables/${payload.recurrable_id}/recurrable_addons/${payload.addon_id}`, 'delete', {}, authConfig())
	},
	checkoutBasket(payload) {
		return authReq('/users/me/basket', 'post', payload, authConfig())
	},
	checkoutExpressBasket(payload) {
		return authReq('/users/me/basket/express', 'post', payload, authConfig())
	},
	checkoutExpressPlan(payload) {
		return authReq('/users/me/recurrables/express', 'post', payload, authConfig())
	},
	submitUserGiftOption(payload) {
		return authReq(`/users/me/orders/${payload.id}/gift`, 'patch', payload, authConfig())
	},
  updateCancelPauseFlowPlanAmount(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/coffee_plan_amount`, 'patch', { amount: payload.amount, flow: payload.flow }, authConfig())
	},
  updateCancelPauseFlowPlanTier(payload) {
		return authReq(`/users/me/recurrables/${payload.id}/coffee_plan_tier`, 'patch', { tier: payload.tier, flow: payload.flow }, authConfig())
	},
}
